import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Process from "../components/Sections/Process/Process"
import Services from "../components/Sections/Services/Services"
import Contact from "../components/Sections/Contact/Contact"

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [isLoading])

  return (
    <React.Fragment>
      <SEO title="Home" />
      <Process />
      <Services />
      <Contact />
    </React.Fragment>
  )
}

export default IndexPage
