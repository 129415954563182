export const ServicesContent = [
  {
    headerText: "Design + Review",
    displayText: "Making a statement and creating the impossible with cutting edge design.",
    timeout: 500
  },
  {
    headerText: "Landscape Design",
    displayText: "Integrating nature to bridge indoor and outdoor spaces seamlessly.",
    timeout: 700
  },
  {
    headerText: "Interior Design",
    displayText: "Form and function meets harmony as a story is told through the details. ",
    timeout: 900
  },
  {
    headerText: "Lighting + Shade",
    displayText: "Architectural and natural light creates the emotions and layers of a space.",
    timeout: 500
  },
  {
    headerText: "Decor",
    displayText: "Decor speaks a unique language and defines the character of the home. ",
    timeout: 700
  },
  {
    headerText: "Administration",
    displayText: "Precise detail can only be executed alongside a dedicated team and a proven process.",
    timeout: 900
  }
]
