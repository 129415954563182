import React from "react"
import classes from "./ServiceItem.module.css"
import FadeInSection from "../../../FadeInSection/FadeInSection"

const ServiceItem = props => {
  const { headerText, displayText, timeout } = props
  return (
    <div className={classes.serviceItemContainer}>
      <FadeInSection timeout={timeout}>
        <h3>{headerText}</h3>
      </FadeInSection>
      <FadeInSection timeout={timeout + 100}>
        <p className={classes.displayText}>{displayText}</p>
      </FadeInSection>
    </div>
  )
}

export default ServiceItem
