import React from "react"
import Lottie from "react-lottie"
import animationData from "./data.json"
import classes from "./Lottie.module.css"

const ProcessLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className={classes.lottie}>
      <Lottie
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled
      />
    </div>
  )
}

export default ProcessLottie
