import React from "react"
import classes from "./Process.module.css"
import ContentContainer from "../../ContentContainer/ContentContainer"
import BlackHeaderText from "../components/BlackHeaderText/BlackHeaderText"
import ProcessLottie from "./Lottie/Lottie"
import FadeInSection from "../../FadeInSection/FadeInSection"

const Process = () => {
  const displayText = "Process"
  return (
    <section id="process" className={classes.processRoot}>
      <FadeInSection timeout={700}>
        <div className={classes.processSubContainer}>
          <div className={classes.processSubTitle}>
            <div className={classes.processLabel}>
              <span>
                {"It starts,".toUpperCase()}
              </span>
              <span>
                {"With a vision.".toUpperCase()}
              </span>
            </div>
          </div>
          <div className={classes.horizontalSpace} />
          <div className={classes.processSubTitle}>
            <img
              src={require("../../../images/7.jpg")}
              className={classes.image}
              alt="description of image1"
            />
          </div>
          <div className={classes.horizontalSpace} />
          <div className={classes.processSubTitle}>
            <img
              src={require("../../../images/16.jpg")}
              className={classes.image}
              alt="description of image2"
            />
          </div>
        </div>
        <div className={classes.processSubContainer}>
          <div className={classes.processSubTitle}>
            <img
              src={require("../../../images/9.jpg")}
              className={classes.image}
              alt="description of image3"
            />
          </div>
          <div className={classes.horizontalSpace} />
          <div className={classes.processSubTitle}>
            <img
              src={require("../../../images/10.jpg")}
              className={classes.image}
              alt="description of image6"
            />
          </div>
        </div>
      </FadeInSection>
      <ContentContainer>
        <div className={classes.processContainer}>
          <div className={classes.leftContainer}>
            <BlackHeaderText displayText={displayText} />
            <FadeInSection timeout={500}>
              <p className={classes.paragraphText}>
                Our proven method provides a seamless and unique approach. We
                take on the complexities while providing you with a simple path.
              </p>
            </FadeInSection>
          </div>
          <div className={classes.rightContainer}>
            <FadeInSection timeout={700}>
              <ProcessLottie />
            </FadeInSection>
          </div>
        </div>
      </ContentContainer>
    </section>
  )
}

export default Process
