import React from "react"
import classes from "./Services.module.css"
import ContentContainer from "../../ContentContainer/ContentContainer"
import BlackHeaderText from "../components/BlackHeaderText/BlackHeaderText"
import {ServicesContent} from "./ServicesContent"
import ServiceItem from "../components/ServiceItem/ServiceItem"

const Services = () => {
  const displayText = "Services"
  return (
    <section id="services" className={classes.servicesRoot}>
      <ContentContainer>
        <BlackHeaderText displayText={displayText}/>
        <div className={classes.servicesContainer}>
          {ServicesContent.map((item, index) => (
              <ServiceItem {...item} key={index} />
          ))}
        </div>
      </ContentContainer>
    </section>
  )
}

export default Services
